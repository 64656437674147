"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryByDisplayValue = exports.queryAllByDisplayValue = exports.getByDisplayValue = exports.getAllByDisplayValue = exports.findByDisplayValue = exports.findAllByDisplayValue = void 0;

var _makeQueries = require("./makeQueries");

var _filterNodeByType = require("./filterNodeByType");

var _errors = require("./errors");

const getTextInputNodeByDisplayValue = (node, value) => {
  try {
    const {
      TextInput
    } = require('react-native');

    const nodeValue = node.props.value !== undefined ? node.props.value : node.props.defaultValue;
    return (0, _filterNodeByType.filterNodeByType)(node, TextInput) && (typeof value === 'string' ? value === nodeValue : value.test(nodeValue));
  } catch (error) {
    throw (0, _errors.createLibraryNotSupportedError)(error);
  }
};

const queryAllByDisplayValue = instance => function queryAllByDisplayValueFn(displayValue) {
  return instance.findAll(node => getTextInputNodeByDisplayValue(node, displayValue));
};

exports.queryAllByDisplayValue = queryAllByDisplayValue;

const getMultipleError = displayValue => `Found multiple elements with display value: ${String(displayValue)} `;

const getMissingError = displayValue => `Unable to find an element with displayValue: ${String(displayValue)}`;

const {
  getBy: getByDisplayValue,
  getAllBy: getAllByDisplayValue,
  queryBy: queryByDisplayValue,
  findBy: findByDisplayValue,
  findAllBy: findAllByDisplayValue
} = (0, _makeQueries.makeQueries)(queryAllByDisplayValue, getMissingError, getMultipleError);
exports.findAllByDisplayValue = findAllByDisplayValue;
exports.findByDisplayValue = findByDisplayValue;
exports.queryByDisplayValue = queryByDisplayValue;
exports.getAllByDisplayValue = getAllByDisplayValue;
exports.getByDisplayValue = getByDisplayValue;