"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeQueries = makeQueries;

var _waitFor = _interopRequireDefault(require("../waitFor"));

var _errors = require("./errors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function makeQueries(queryAllByQuery, getMissingError, getMultipleError) {
  function getAllByQuery(instance) {
    return function getAllFn(args) {
      const results = queryAllByQuery(instance)(args);

      if (results.length === 0) {
        throw new _errors.ErrorWithStack(getMissingError(args), getAllFn);
      }

      return results;
    };
  }

  function queryByQuery(instance) {
    return function singleQueryFn(args) {
      const results = queryAllByQuery(instance)(args);

      if (results.length > 1) {
        throw new _errors.ErrorWithStack(getMultipleError(args), singleQueryFn);
      }

      if (results.length === 0) {
        return null;
      }

      return results[0];
    };
  }

  function getByQuery(instance) {
    return function getFn(args) {
      const results = queryAllByQuery(instance)(args);

      if (results.length > 1) {
        throw new _errors.ErrorWithStack(getMultipleError(args), getFn);
      }

      if (results.length === 0) {
        throw new _errors.ErrorWithStack(getMissingError(args), getFn);
      }

      return results[0];
    };
  }

  function findAllByQuery(instance) {
    return function findAllFn(args, waitForOptions = {}) {
      return (0, _waitFor.default)(() => getAllByQuery(instance)(args), waitForOptions);
    };
  }

  function findByQuery(instance) {
    return function findFn(args, waitForOptions = {}) {
      return (0, _waitFor.default)(() => getByQuery(instance)(args), waitForOptions);
    };
  }

  return {
    getBy: getByQuery,
    getAllBy: getAllByQuery,
    queryBy: queryByQuery,
    findBy: findByQuery,
    findAllBy: findAllByQuery
  };
}