"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryByPlaceholderText = exports.queryAllByPlaceholderText = exports.getByPlaceholderText = exports.getAllByPlaceholderText = exports.findByPlaceholderText = exports.findAllByPlaceholderText = void 0;

var _makeQueries = require("./makeQueries");

var _filterNodeByType = require("./filterNodeByType");

var _errors = require("./errors");

const getTextInputNodeByPlaceholderText = (node, placeholder) => {
  try {
    const {
      TextInput
    } = require('react-native');

    return (0, _filterNodeByType.filterNodeByType)(node, TextInput) && (typeof placeholder === 'string' ? placeholder === node.props.placeholder : placeholder.test(node.props.placeholder));
  } catch (error) {
    throw (0, _errors.createLibraryNotSupportedError)(error);
  }
};

const queryAllByPlaceholderText = instance => function queryAllByPlaceholderFn(placeholder) {
  return instance.findAll(node => getTextInputNodeByPlaceholderText(node, placeholder));
};

exports.queryAllByPlaceholderText = queryAllByPlaceholderText;

const getMultipleError = placeholder => `Found multiple elements with placeholder: ${String(placeholder)} `;

const getMissingError = placeholder => `Unable to find an element with placeholder: ${String(placeholder)}`;

const {
  getBy: getByPlaceholderText,
  getAllBy: getAllByPlaceholderText,
  queryBy: queryByPlaceholderText,
  findBy: findByPlaceholderText,
  findAllBy: findAllByPlaceholderText
} = (0, _makeQueries.makeQueries)(queryAllByPlaceholderText, getMissingError, getMultipleError);
exports.findAllByPlaceholderText = findAllByPlaceholderText;
exports.findByPlaceholderText = findByPlaceholderText;
exports.queryByPlaceholderText = queryByPlaceholderText;
exports.getAllByPlaceholderText = getAllByPlaceholderText;
exports.getByPlaceholderText = getByPlaceholderText;