"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _act = _interopRequireDefault(require("./act"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

const isHostElement = element => {
  return typeof (element === null || element === void 0 ? void 0 : element.type) === 'string';
};

const isTextInput = element => {
  const {
    TextInput
  } = require('react-native');

  return (element === null || element === void 0 ? void 0 : element.type) === TextInput;
};

const isTouchResponder = element => {
  if (!isHostElement(element)) return false;
  return !!(element !== null && element !== void 0 && element.props.onStartShouldSetResponder) || isTextInput(element);
};

const isPointerEventEnabled = (element, isParent) => {
  const parentCondition = isParent ? (element === null || element === void 0 ? void 0 : element.props.pointerEvents) === 'box-only' : (element === null || element === void 0 ? void 0 : element.props.pointerEvents) === 'box-none';

  if ((element === null || element === void 0 ? void 0 : element.props.pointerEvents) === 'none' || parentCondition) {
    return false;
  }

  if (!(element !== null && element !== void 0 && element.parent)) return true;
  return isPointerEventEnabled(element.parent, true);
};

const isEventEnabled = (element, touchResponder) => {
  var _touchResponder$props, _touchResponder$props2, _touchResponder$props3, _touchResponder$props4;

  if (isTextInput(element)) return (element === null || element === void 0 ? void 0 : element.props.editable) !== false;
  if (!isPointerEventEnabled(element)) return false;
  const touchStart = touchResponder === null || touchResponder === void 0 ? void 0 : (_touchResponder$props = (_touchResponder$props2 = touchResponder.props).onStartShouldSetResponder) === null || _touchResponder$props === void 0 ? void 0 : _touchResponder$props.call(_touchResponder$props2);
  const touchMove = touchResponder === null || touchResponder === void 0 ? void 0 : (_touchResponder$props3 = (_touchResponder$props4 = touchResponder.props).onMoveShouldSetResponder) === null || _touchResponder$props3 === void 0 ? void 0 : _touchResponder$props3.call(_touchResponder$props4);
  if (touchStart || touchMove) return true;
  return touchStart === undefined && touchMove === undefined;
};

const findEventHandler = (element, eventName, callsite, nearestTouchResponder) => {
  const touchResponder = isTouchResponder(element) ? element : nearestTouchResponder;
  const handler = getEventHandler(element, eventName);
  if (handler && isEventEnabled(element, touchResponder)) return handler;

  if (element.parent === null || element.parent.parent === null) {
    return null;
  }

  return findEventHandler(element.parent, eventName, callsite, touchResponder);
};

const getEventHandler = (element, eventName) => {
  const eventHandlerName = toEventHandlerName(eventName);

  if (typeof element.props[eventHandlerName] === 'function') {
    return element.props[eventHandlerName];
  }

  if (typeof element.props[eventName] === 'function') {
    return element.props[eventName];
  }

  return undefined;
};

const invokeEvent = (element, eventName, callsite, ...data) => {
  const handler = findEventHandler(element, eventName, callsite);

  if (!handler) {
    return;
  }

  let returnValue;
  (0, _act.default)(() => {
    returnValue = handler(...data);
  });
  return returnValue;
};

const toEventHandlerName = eventName => `on${eventName.charAt(0).toUpperCase()}${eventName.slice(1)}`;

const pressHandler = (element, ...data) => invokeEvent(element, 'press', pressHandler, ...data);

const changeTextHandler = (element, ...data) => invokeEvent(element, 'changeText', changeTextHandler, ...data);

const scrollHandler = (element, ...data) => invokeEvent(element, 'scroll', scrollHandler, ...data);

const fireEvent = (element, eventName, ...data) => invokeEvent(element, eventName, fireEvent, ...data);

fireEvent.press = pressHandler;
fireEvent.changeText = changeTextHandler;
fireEvent.scroll = scrollHandler;
var _default = fireEvent;
exports.default = _default;