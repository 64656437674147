"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryByText = exports.queryAllByText = exports.getByText = exports.getAllByText = exports.findByText = exports.findAllByText = void 0;

var React = _interopRequireWildcard(require("react"));

var _makeQueries = require("./makeQueries");

var _filterNodeByType = require("./filterNodeByType");

var _errors = require("./errors");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const getChildrenAsText = (children, TextComponent, textContent = []) => {
  React.Children.forEach(children, child => {
    var _child$props;

    if (typeof child === 'string') {
      textContent.push(child);
      return;
    }

    if (typeof child === 'number') {
      textContent.push(child.toString());
      return;
    }

    if (child !== null && child !== void 0 && (_child$props = child.props) !== null && _child$props !== void 0 && _child$props.children) {
      // Bail on traversing text children down the tree if current node (child)
      // has no text. In such situations, react-test-renderer will traverse down
      // this tree in a separate call and run this query again. As a result, the
      // query will match the deepest text node that matches requested text.
      if ((0, _filterNodeByType.filterNodeByType)(child, TextComponent) && textContent.length === 0) {
        return;
      }

      getChildrenAsText(child.props.children, TextComponent, textContent);
    }
  });
  return textContent;
};

const getNodeByText = (node, text) => {
  try {
    const {
      Text
    } = require('react-native');

    const isTextComponent = (0, _filterNodeByType.filterNodeByType)(node, Text);

    if (isTextComponent) {
      const textChildren = getChildrenAsText(node.props.children, Text);

      if (textChildren) {
        const textToTest = textChildren.join('');
        return typeof text === 'string' ? text === textToTest : text.test(textToTest);
      }
    }

    return false;
  } catch (error) {
    throw (0, _errors.createLibraryNotSupportedError)(error);
  }
};

const queryAllByText = instance => function queryAllByTextFn(text) {
  const results = instance.findAll(node => getNodeByText(node, text));
  return results;
};

exports.queryAllByText = queryAllByText;

const getMultipleError = text => `Found multiple elements with text: ${String(text)}`;

const getMissingError = text => `Unable to find an element with text: ${String(text)}`;

const {
  getBy: getByText,
  getAllBy: getAllByText,
  queryBy: queryByText,
  findBy: findByText,
  findAllBy: findAllByText
} = (0, _makeQueries.makeQueries)(queryAllByText, getMissingError, getMultipleError);
exports.findAllByText = findAllByText;
exports.findByText = findByText;
exports.queryByText = queryByText;
exports.getAllByText = getAllByText;
exports.getByText = getByText;