"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.queryByTestId = exports.queryAllByTestId = exports.getByTestId = exports.getAllByTestId = exports.findByTestId = exports.findAllByTestId = void 0;

var _makeQueries = require("./makeQueries");

const getNodeByTestId = (node, testID) => {
  return typeof testID === 'string' ? testID === node.props.testID : testID.test(node.props.testID);
};

const queryAllByTestId = instance => function queryAllByTestIdFn(testId) {
  const results = instance.findAll(node => getNodeByTestId(node, testId)).filter(element => typeof element.type === 'string');
  return results;
};

exports.queryAllByTestId = queryAllByTestId;

const getMultipleError = testId => `Found multiple elements with testID: ${String(testId)}`;

const getMissingError = testId => `Unable to find an element with testID: ${String(testId)}`;

const {
  getBy: getByTestId,
  getAllBy: getAllByTestId,
  queryBy: queryByTestId,
  findBy: findByTestId,
  findAllBy: findAllByTestId
} = (0, _makeQueries.makeQueries)(queryAllByTestId, getMissingError, getMultipleError);
exports.findAllByTestId = findAllByTestId;
exports.findByTestId = findByTestId;
exports.queryByTestId = queryByTestId;
exports.getAllByTestId = getAllByTestId;
exports.getByTestId = getByTestId;