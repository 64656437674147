"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getByAPI = exports.UNSAFE_getAllByProps = exports.UNSAFE_getAllByType = exports.UNSAFE_getByProps = exports.UNSAFE_getByType = void 0;

var React = _interopRequireWildcard(require("react"));

var _prettyFormat = _interopRequireDefault(require("pretty-format"));

var _errors = require("./errors");

var _byTestId = require("./byTestId");

var _byText = require("./byText");

var _byPlaceholderText = require("./byPlaceholderText");

var _byDisplayValue = require("./byDisplayValue");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function () { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

const UNSAFE_getByType = instance => function getByTypeFn(type) {
  try {
    return instance.findByType(type);
  } catch (error) {
    throw new _errors.ErrorWithStack((0, _errors.prepareErrorMessage)(error), getByTypeFn);
  }
};

exports.UNSAFE_getByType = UNSAFE_getByType;

const UNSAFE_getByProps = instance => function getByPropsFn(props) {
  try {
    return instance.findByProps(props);
  } catch (error) {
    throw new _errors.ErrorWithStack((0, _errors.prepareErrorMessage)(error), getByPropsFn);
  }
};

exports.UNSAFE_getByProps = UNSAFE_getByProps;

const UNSAFE_getAllByType = instance => function getAllByTypeFn(type) {
  const results = instance.findAllByType(type);

  if (results.length === 0) {
    throw new _errors.ErrorWithStack('No instances found', getAllByTypeFn);
  }

  return results;
};

exports.UNSAFE_getAllByType = UNSAFE_getAllByType;

const UNSAFE_getAllByProps = instance => function getAllByPropsFn(props) {
  const results = instance.findAllByProps(props);

  if (results.length === 0) {
    throw new _errors.ErrorWithStack(`No instances found with props:\n${(0, _prettyFormat.default)(props)}`, getAllByPropsFn);
  }

  return results;
};

exports.UNSAFE_getAllByProps = UNSAFE_getAllByProps;

const getByAPI = instance => ({
  getByText: (0, _byText.getByText)(instance),
  getByPlaceholderText: (0, _byPlaceholderText.getByPlaceholderText)(instance),
  getByDisplayValue: (0, _byDisplayValue.getByDisplayValue)(instance),
  getByTestId: (0, _byTestId.getByTestId)(instance),
  getAllByText: (0, _byText.getAllByText)(instance),
  getAllByPlaceholderText: (0, _byPlaceholderText.getAllByPlaceholderText)(instance),
  getAllByDisplayValue: (0, _byDisplayValue.getAllByDisplayValue)(instance),
  getAllByTestId: (0, _byTestId.getAllByTestId)(instance),
  // Unsafe
  UNSAFE_getByType: UNSAFE_getByType(instance),
  UNSAFE_getAllByType: UNSAFE_getAllByType(instance),
  UNSAFE_getByProps: UNSAFE_getByProps(instance),
  UNSAFE_getAllByProps: UNSAFE_getAllByProps(instance),
  // Removed
  getByName: () => (0, _errors.throwRemovedFunctionError)('getByName', 'migration-v2#removed-functions'),
  getAllByName: () => (0, _errors.throwRemovedFunctionError)('getAllByName', 'migration-v2#removed-functions'),
  getByType: () => (0, _errors.throwRemovedFunctionError)('getByType', 'migration-v2#removed-functions'),
  getAllByType: () => (0, _errors.throwRemovedFunctionError)('getAllByType', 'migration-v2#removed-functions'),
  getByProps: () => (0, _errors.throwRemovedFunctionError)('getByProps', 'migration-v2#removed-functions'),
  getAllByProps: () => (0, _errors.throwRemovedFunctionError)('getAllByProps', 'migration-v2#removed-functions'),
  // Renamed
  getByPlaceholder: () => (0, _errors.throwRenamedFunctionError)('getByPlaceholder', 'getByPlaceholderText'),
  getAllByPlaceholder: () => (0, _errors.throwRenamedFunctionError)('getAllByPlaceholder', 'getByPlaceholderText')
});

exports.getByAPI = getByAPI;