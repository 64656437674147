"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = waitForElementToBeRemoved;

var _waitFor = _interopRequireDefault(require("./waitFor"));

var _errors = require("./helpers/errors");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function isRemoved(result) {
  return !result || Array.isArray(result) && !result.length;
}

async function waitForElementToBeRemoved(expectation, options) {
  // Created here so we get a nice stacktrace
  const timeoutError = new _errors.ErrorWithStack('Timed out in waitForElementToBeRemoved.', waitForElementToBeRemoved); // Elements have to be present initally and then removed.

  const initialElements = expectation();

  if (isRemoved(initialElements)) {
    throw new _errors.ErrorWithStack('The element(s) given to waitForElementToBeRemoved are already removed. waitForElementToBeRemoved requires that the element(s) exist(s) before waiting for removal.', waitForElementToBeRemoved);
  }

  return (0, _waitFor.default)(() => {
    let result;

    try {
      result = expectation();
    } catch (error) {
      return initialElements;
    }

    if (!isRemoved(result)) {
      throw timeoutError;
    }

    return initialElements;
  }, options);
}