"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findByAPI = void 0;

var _byTestId = require("./byTestId");

var _byText = require("./byText");

var _byPlaceholderText = require("./byPlaceholderText");

var _byDisplayValue = require("./byDisplayValue");

var _errors = require("./errors");

const findByAPI = instance => ({
  findByTestId: (0, _byTestId.findByTestId)(instance),
  findByText: (0, _byText.findByText)(instance),
  findByPlaceholderText: (0, _byPlaceholderText.findByPlaceholderText)(instance),
  findByDisplayValue: (0, _byDisplayValue.findByDisplayValue)(instance),
  findAllByTestId: (0, _byTestId.findAllByTestId)(instance),
  findAllByText: (0, _byText.findAllByText)(instance),
  findAllByPlaceholderText: (0, _byPlaceholderText.findAllByPlaceholderText)(instance),
  findAllByDisplayValue: (0, _byDisplayValue.findAllByDisplayValue)(instance),
  // Renamed
  findByPlaceholder: () => (0, _errors.throwRenamedFunctionError)('findByPlaceholder', 'findByPlaceholderText'),
  findAllByPlaceholder: () => (0, _errors.throwRenamedFunctionError)('findAllByPlaceholder', 'findAllByPlaceholderText')
});

exports.findByAPI = findByAPI;